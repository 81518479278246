<template>
    <div class="skeleton-loader" :class="parent">
        <div v-for="index in 5" :key="index">
            <v-skeleton-loader type="image" class="vote_info"></v-skeleton-loader>
            <v-skeleton-loader type="image" class="suggest_info"></v-skeleton-loader>
        </div>
    </div>
</template>

<script>
export default {
    name: "suggestionSkeletonLoader",
    props: ['parent']
}
</script>

<style scoped>

</style>