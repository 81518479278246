<template>
    <supportLayout>
        <div class="p-feature-suggestions full_page">

            <v-container v-if="underConstruction === true">
                <div class="coming_soon">
                    <h3>Coming soon</h3>
                </div>
            </v-container>

            <v-container v-if="underConstruction === false">
                <main>
                    <h2 class="suggest_heading">
                        <span v-if="!createdSuccess">Suggest a feature</span>
                        <span v-else>Thank you! Have another idea?</span>
                    </h2>

                    <div class="input_box">
                        <label>What would make Psychurch better?</label>

                        <div class="input_row">
                            <button type="button" class="go_search" :class="{'active': workForm === 'create'}" @click="prepareCreateSuggest"></button>
                            <input type="text" placeholder="Enter your suggestion here" @input="$v.suggestName.$touch()"  @blur="$v.suggestName.$touch()"
                                   v-model.trim="suggestName"
                                   @keydown.enter="prepareCreateSuggest" class="input-style-2" :class="{'input_error': $v.suggestName.$error && workForm === 'create'}">
                            <div class="error-msg" v-if="$v.suggestName.$error && workForm === 'create'">*Required</div>
                        </div>

                        <template v-if="workForm === 'create'">
                            <div class="textarea_row">
                                <v-textarea
                                    @input="$v.suggestDescription.$touch()"
                                    @blur="$v.suggestDescription.$touch()"
                                    filled
                                    counter
                                    auto-grow
                                    placeholder="Describe your idea"
                                    :rules="textareaRules"
                                    v-model="suggestDescription"
                                    :class="{'input_error': $v.suggestDescription.$error}"
                                ></v-textarea>
                                <div class="error-msg" v-if="$v.suggestDescription.$error">*Required</div>
                            </div>

                            <button type="button" class="primary_btn large" @click="createSuggest"
                                    :class="{'disabled': !suggestName.length || !suggestDescription.length}">Submit</button>
                        </template>
                    </div>

                    <div class="wrap_suggestions">
                        <v-tabs
                            v-if="activeTab && !suggestName.length"
                            background-color="transparent"
                            color="#fff"
                            v-model="activeTabsSuggest">
                            <v-tab v-for="tab in tabsSectionsSuggest" :key="tab.tab" @change="changeTab(tab.tab)">
                                {{ tab.name }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items touchless>
                            <itemSuggestion :suggestions="suggestionsList" :activeTab="activeTab" :searchText="suggestName"></itemSuggestion>
                        </v-tabs-items>


                        <v-progress-circular
                            v-if="loading"
                            :size="80"
                            :width="2"
                            color="#20C4F5"
                            indeterminate
                        ></v-progress-circular>
                        <button class="button_load_more" @click="loadMore" v-if="!loading && (currentPage < totalPages)">Show More</button>
                    </div>

                </main>
                <aside>
                    <div class="global_stats" v-if="globalStats">
                        <div class="heading">Global Stats</div>
                        <div class="stats_list">
                            <div class="item_stats">
                                <div class="name">Total Suggested</div>
                                <div class="value">{{ globalStats[0].stats }}</div>
                            </div>
                            <div class="item_stats">
                                <div class="name">Planned</div>
                                <div class="value">-</div>
                            </div>
                            <div class="item_stats">
                                <div class="name">In Progress</div>
                                <div class="value">-</div>
                            </div>
                            <div class="item_stats">
                                <div class="name">Completed</div>
                                <div class="value">-</div>
                            </div>
                        </div>
                    </div>
                    <div class="available_stats" v-if="availableStats">
                        <div class="heading_main_info" v-if="availableInfo">
                            You get 10 free votes every month
                        </div>
                        <div class="heading">
                            Your Votes
                        </div>

                        <div class="items_rows">
                            <div class="item_info">
                                <div class="name">
                                    Available
                                    <span class="item_info" @mouseover="availableInfo = true" @mouseleave="availableInfo = false"></span>
                                </div>
                                <div class="value">{{ availableStats.available }}</div>
                            </div>
                            <div class="item_info">
                                <div class="name">Used</div>
                                <div class="value">{{ availableStats.used }}</div>
                            </div>
                            <div class="item_info">
                                <div class="name">Purchased</div>
                                <div class="value">{{ availableStats.purchased }}</div>
                            </div>
                            <div class="buy_more_votes">
                                Buy more votes
                            </div>
                        </div>

                    </div>
                </aside>

            </v-container>
        </div>
    </supportLayout>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import supportLayout from "@/layouts/supportLayout.vue";
import itemSuggestion from "@/components/itemSuggestion";

export default {
    name: 'feature-suggestions',
    components: {
        supportLayout,
        itemSuggestion
    },
    data() {
        return {
            workForm: 'category',
            createdSuccess: false,

            activeTabsSuggest: '',
            tabsSectionsSuggest: [
                {index: 0, name: 'Top', tab: 'top', access: 'default'},
                {index: 1, name: 'New', tab: 'new', access: 'default'},
                {index: 2, name: 'Planned', tab: 'planned', access: 'default'},
                {index: 3, name: 'In Progress', tab: 'progress', access: 'default'},
                {index: 4, name: 'Completed', tab: 'completed', access: 'default'},
                {index: 5, name: 'Following', tab: 'following', access: 'auth'}
            ],
            activeTab: 'top', // default

            suggestName: '',
            suggestDescription: '',
            textareaRules: [v => v.length <= 25 || 'Max 25 characters'],
            suggestionsList: [],
            bottomOfWindow: false,
            perPage: 5,
            availableInfo: false,

            authIsAdmin: false,
            underConstruction: true,
        }
    },
    validations: {
        suggestName: {
            required
        },
        suggestDescription: {
            required
        }
    },
    watch: {
        suggestName() {
            if (!this.suggestName.length) {
                this.$v.reset();
                this.$v.suggestName.reset();
            }
            this.clearSuggestionList();
        },
        suggestDescription() {
            this.$v.reset();
            if (!this.suggestDescription.length) {
                this.$v.suggestDescription.reset();
            }
        },

        activeTab() {
            this.clearSuggestionList();
        },
        currentPage() {
            if (this.currentPage !== 1) {
                this.search();
            }
        },
        bottomOfWindow() {
            if (this.bottomOfWindow === true && (this.totalPages > this.currentPage) && !this.loading) {
                this.loadMore();
            }
        }
    },
    computed: {
        ...mapGetters({
            authData: 'AUTH_DATA',
            suggestions: 'suggestions/SUGGESTIONS',
            totalSuggestions: 'suggestions/TOTAL_SUGGESTIONS',
            currentPage: 'suggestions/CURRENT_PAGE',
            totalPages: 'suggestions/TOTAL_PAGES',

            //
            globalStats: 'suggestions/GLOBAL_STATS',
            availableStats: 'suggestions/AVAILABLE_VOTE_STATS',

            //
            loading: 'suggestions/SUGGESTIONS_LOADING'
        })
    },
    created() {
        this.authIsAdmin = this.authData.userRoles.includes('ROLE_ADMIN');
        if (!this.authData.accessToken) {
            this.tabsSectionsSuggest = this.tabsSectionsSuggest.filter(el => el.access === 'default');
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll);
        this.search();
        this.getGlobalStats();
        if (this.authData.accessToken) {
            this.getAvailableStats();
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        ...mapActions({
            createNewSuggest: 'suggestions/CREATE_SUGGEST',
            getSuggestions: 'suggestions/GET_SUGGESTIONS',

            getGlobalStats: 'suggestions/GET_GLOBAL_STATS',
            getAvailableStats: 'suggestions/GET_AVAILABLE_VOTE_STATS'
        }),
        clearSuggestionList() {
            if (this.currentPage !== 1) {
                this.$store.dispatch('suggestions/UPDATE_CURRENT_PAGE', 1);
            }
            this.suggestionsList = [];
            this.search();
        },
        // delayTouch($v) {
        //     $v.$reset();
        // },
        changeTab(activeTab) {
            this.activeTab = activeTab;
        },
        prepareCreateSuggest() {
            if (this.authData.accessToken) {
                this.workForm = 'create';
            }
        },
        createSuggest() {
            this.$v.$touch();

            if (this.suggestName.length && this.suggestDescription && this.$v.$error === false) {
                let params = {
                    title: this.suggestName,
                    description: this.suggestDescription,
                }
                this.createNewSuggest(params)
                    .then(() => {
                        this.suggestName = '';
                        this.suggestDescription = '';
                        this.workForm = '';

                        this.createdSuccess = true;
                        setTimeout(() => {
                            this.createdSuccess = false;
                        }, 2500);

                    })
                    .catch(err => console.error(`createSuggest ${err}`))
                    .finally(() => {
                        // this.activeTabsSuggest = 1;
                    })
            }
        },

        search() {
            const params = {
                perPage: this.perPage,
                page: this.currentPage,
            }

            if (this.suggestName.length) {
                params.title = this.suggestName;
            }
            if (this.activeTab && !this.suggestName.length) {
                params.category = this.activeTab;
            }
            this.getSuggestions(params)
                .then(() => {

                })
                .catch(err => console.error(`getSuggestions ${err}`))
                .finally(() => {
                    if (this.currentPage === 1) {
                        this.suggestionsList = this.suggestions;
                    } else {
                        for (let i of this.suggestions) {
                            this.suggestionsList.push(i);
                        }
                    }
                });
        },
        loadMore() {
            let page = this.currentPage;
            this.$store.dispatch('suggestions/UPDATE_CURRENT_PAGE', page += 1);
        },
        onScroll() {
            this.bottomOfWindow = (window.innerHeight + window.scrollY) >= document.body.scrollHeight;
        },

    }
}
</script>
