<template>
    <div class="wrap_item_suggestion">
        <suggestionSkeletonLoader :parent="'suggestions'" v-if="!suggestions.length && getLoading"></suggestionSkeletonLoader>
        <nothingHereYet v-if="!suggestions.length && !getLoading"></nothingHereYet>
        <div class="item_suggest" v-for="suggest of suggestions" :key="suggest.id">
            <div class="main_suggest_info">
                <div class="label_votes">
                    <div class="votes">
                        <span class="val">{{ suggest.voteScore }}</span>
                        votes
                    </div>
                    <button type="button" class="add_votes" v-if="suggest.category === 'top' || suggest.category === 'new'" @click="addVote(suggest.id)">
                        +1
                    </button>
                    <div v-else class="category_label">
                        {{ suggest.category }}

                    </div>
                </div>
                <div class="suggest_info">
                    <v-menu
                        transition="slide-y-transition"
                        :close-on-content-click="true"
                        :nudge-width="120"
                        offset-y
                        nudge-left="60"
                        nudge-bottom="15"
                        origin="top center"
                        content-class="rounds">
                        <template v-slot:activator="{ on, attrs }">
                            <button type="button" v-ripple class="suggest_menu" v-bind="attrs" v-on="on">
                                <span class="material-icons" v-bind="attrs" v-on="on">more_horiz</span>
                            </button>
                        </template>

                        <v-list v-if="authIsAdmin" class="admin_bg">
                            <v-list-item v-ripple @click="changeStatus('planned')">
                                <button type="button">Mark Planned</button>
                            </v-list-item>
                            <v-list-item v-ripple  @click="changeStatus('completed')">
                                <button type="button">Mark Completed</button>
                            </v-list-item>
                            <v-list-item v-ripple v-if="authData && authData.userId === suggest.ownerId" @click="edit(suggest.id)">
                                <button type="button">Edit</button>
                            </v-list-item>
                            <v-list-item v-ripple class="remove" @click="remove(suggest)">
                                <button type="button">Remove</button>
                            </v-list-item>
                        </v-list>

                        <v-list v-else-if="authData && authData.userId === suggest.ownerId">
                            <v-list-item v-ripple @click="edit(suggest.id)">
                                <button type="button">Edit</button>
                            </v-list-item>
                            <v-list-item v-ripple class="remove" @click="remove(suggest)">
                                <button type="button">Remove</button>
                            </v-list-item>
                        </v-list>

                        <v-list v-if="!authIsAdmin && authData.userId !== suggest.ownerId">
                            <v-list-item v-ripple>
                                <button type="button">Follow</button>
                            </v-list-item>
                            <v-list-item v-ripple disabled>
                                <button type="button">Report</button>
                            </v-list-item>
                        </v-list>


                    </v-menu>
                    <div class="name_suggest" v-html="highlightText(suggest.title)">

                    </div>
                    <div class="description_suggest" v-html="highlightText(suggest.description)"></div>

                    <div class="comments_block">
                        <div class="count_comments" :class="{'active': showCommentsID === suggest.id}" @click="showComments(suggest.id)">{{suggest.commentsCount}} Comments</div>
                    </div>
                </div>
            </div>
            <template v-if="showCommentsID === suggest.id">
                <comments :suggestionID="suggest.id" :commentsLocation="'suggestions'"></comments>
            </template>

        </div>
        <suggestionsAvailableVotes v-if="availableVotesDialog" v-model="availableVotesDialog"></suggestionsAvailableVotes>
        <dialogNeedLogin v-if="dialogNeedLoginVisible" v-model="dialogNeedLoginVisible"></dialogNeedLogin>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

import suggestionSkeletonLoader from "@/components/small/skeleton-loaders/suggestionSkeletonLoader";
import nothingHereYet from "@/components/small/nothingHereYet";
import suggestionsAvailableVotes from "@/components/dialogs/suggestionsAvailableVotes";

const comments = () => import("@/components/commentsV2");
const dialogNeedLogin = () => import('@/components/dialogs/needLogin');

export default {
    name: "itemSuggestion",
    props: ['suggestions', 'activeTab', 'searchText'],
    components: {
        comments,
        suggestionSkeletonLoader,
        nothingHereYet,
        suggestionsAvailableVotes,
        dialogNeedLogin
    },
    data() {
        return {
            availableVotesDialog: false,
            showCommentsID: null,
            dialogNeedLoginVisible: false,
            authIsAdmin: false
        }
    },
    computed: {
        ...mapGetters({
            authData: 'AUTH_DATA',
            getLoading: 'suggestions/SUGGESTIONS_LOADING',
            availableVotes: 'suggestions/AVAILABLE_VOTE_STATS'
        }),
    },
    created() {
        this.authIsAdmin = this.authData.userRoles.includes('ROLE_ADMIN');
    },
    methods: {
        ...mapActions({
            addVoteToSuggestion: 'suggestions/ADD_VOTE_TO_SUGGESTION',
            deleteSuggest: 'suggestions/DELETE_SUGGEST',
        }),
        addVote(id) {
            if (this.authData.accessToken) {
                if (this.availableVotes && this.availableVotes.available < 1) {
                    this.availableVotesDialog = true;
                } else {
                    this.addVoteToSuggestion(id)
                        .then(() => {

                        })
                        .catch(err => console.log(`addVote, ${err}`));
                }
            } else {
                this.dialogNeedLoginVisible = true;
            }
        },
        highlightText(text) {
            if (this.searchText.length) {
                return text.replaceAll(this.searchText, `<span class="highlight">${this.searchText}</span>`)
            }
            return text;
        },
        showComments(id) {
            this.showCommentsID = id;
        },
        changeStatus(status) {

        },
        edit(id) {

        },
        remove(suggest) {
            this.deleteSuggest(suggest.id).then(() => {
                let index = this.suggestions.indexOf(suggest);
                if (index > -1) {
                    this.suggestions.splice(index, 1);
                }
            })
        }
    }
}
</script>

<style scoped>

</style>

