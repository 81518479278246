<template>
    <v-dialog
        :value="value" @input="$emit('input')"
        max-width="574">

        <div class="need_login_dialog style_dialog">
            <h2 class="header">
                Oops!
            </h2>
            <div class="cnt">
                You get 10 free votes every month
            </div>
            <div class="wrap_actions">
                <button type="button" class="cancel" @click="dialog = false" v-ripple>Cancel</button>
                <button type="button" class="go_to_login" @click="dialog = false" v-ripple>Buy more votes</button>
<!--                <routes-link :to="{name: 'login'}" class="go_to_login" v-ripple>Buy more votes</routes-link>-->
            </div>
        </div>
    </v-dialog>
</template>
<script>
export default {
    name: "suggestionsAvailableVotes",
    props: ['value'],
    data() {
        return {}
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>

<style scoped>

</style>